<template>
  <v-navigation-drawer
    style="z-index: 9999"
    v-model="drawer"
    height="100vh"
    touchless
    fixed
    right
  >
    <v-icon
      @click="drawer = false"
      class="position-absolute"
      style="top: 10px; right: 10px; z-index: 999"
      >mdi-close</v-icon
    >
    <v-row :class="{ 'mb-6': !isLoggedIn }">
      <v-col class="text-center bg-green-light" cols="12">
        <v-avatar color="#EFEFEF" size="100" class="mt-8">
          <template v-if="customer && customer.avatar">
            <img :src="avatar" />
          </template>
          <v-icon v-else dark size="80" color="#CBCBCB"> mdi-account </v-icon>
        </v-avatar>
        <p v-if="customer" class="title font-weight-light">
          {{ customerName }}
        </p>
        <p v-else class="title font-weight-light">Not Logged In</p>
        <template v-if="customer"
          ><p
            v-if="customer.contact_phone"
            class="text-body-2 font-weight-light"
          >
            <img src="@/assets/images/mayflag.svg" style="width: 16px" />
            {{ customer.contact_phone }}
          </p>
        </template>
      </v-col>
    </v-row>
    <v-list flat class="sidedrawerlist">
      <v-list-item-group>
        <template v-if="isLoggedIn">
          <v-list-item @click="routetoPage('profile')">
            <v-list-item-icon>
              <v-icon v-text="'mdi-account-details'" color="primary"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Profile'"
                class="text-body-2 font-weight-6"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="routetoPage('addresseshome')">
            <v-list-item-icon>
              <v-icon v-text="'mdi-map-marker'" color="primary"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Addresses'"
                class="text-body-2 font-weight-6"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="routetoPage('reservation')">
            <v-list-item-icon>
              <v-icon v-text="'mdi-calendar'" color="primary"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Reservations'"
                class="text-body-2 font-weight-6"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="routetoPage('orders')">
            <v-list-item-icon>
              <v-icon v-text="'mdi-book'" color="primary"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Order History'"
                class="text-body-2 font-weight-6"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="routetoPage('vouchers')">
            <v-list-item-icon>
              <v-icon v-text="'mdi-wallet'" color="primary"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Voucher Wallet'"
                class="text-body-2 font-weight-6"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout(onLogout)">
            <v-list-item-icon>
              <v-icon
                v-text="'mdi-logout'"
                color="rgba(0, 0, 0, 0.38)"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Logout'"
                class="text-body-2 font-weight-6 text--disabled"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="m-0"></v-divider>
        </template>
        <v-list-item @click="routetoPage('orders')" v-if="!isLoggedIn">
          <v-list-item-icon>
            <v-icon v-text="'mdi-food'" color="primary"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="'Guest Orders'"
              class="text-body-2 font-weight-6"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="routetoPage('vouchers')" v-if="!isLoggedIn">
          <v-list-item-icon>
            <v-icon v-text="'mdi-wallet'" color="primary"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="'Unclaimed Vouchers'"
              class="text-body-2 font-weight-6"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon v-text="'mdi-text-box-search-outline'" color="primary"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <router-link
              style="color: #000000 !important"
              class="text-body-2 font-weight-6"
              :to="{ name: 'home' }"
              >Terms & Conditions</router-link
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon v-text="'mdi-shield-lock-outline'" color="primary"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <router-link
              style="color: #000000 !important"
              class="text-body-2 font-weight-6"
              :to="{ name: 'home' }"
              >Privacy Policy</router-link
            >
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-spacer></v-spacer>
    <v-btn
      v-if="!isLoggedIn"
      @click="routetoPage('login')"
      fixed
      bottom
      ripple
      block
      tile
      color="primary"
      class="text-body-2 font-weight-6 mb-12"
      >Login</v-btn
    >
    <v-btn
      @click="install()"
      fixed
      bottom
      ripple
      block
      tile
      color="primary"
      class="text-body-2 font-weight-6 mb-1"
      v-if="installable"
    >
      Install
    </v-btn>
    <div class="version">{{ version }}</div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
	props: ['visible', 'installable'],
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      version: process.env.VUE_APP_VERSION,
      items: [
        {
          icon: 'mdi-face-profile',
          text: 'Profile',
          routename: 'profile',
          requireslogin: true,
        },
        {
          icon: 'mdi-map-marker',
          text: 'Addresses',
          routename: 'addresseshome',
          requireslogin: true,
        },
        {
          icon: 'mdi-calendar',
          text: 'Reservations',
          requireslogin: true,
          routename: 'reservation',
        },
        {
          icon: 'mdi-book',
          text: 'Order Tracking',
          requireslogin: true,
          routename: 'orders',
        },
        {
          icon: 'mdi-wallet',
          text: 'Voucher Wallet',
          requireslogin: true,
        },
        {
          icon: 'mdi-logout',
          text: 'Logout',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('customer', {
      customerName: 'getName',
    }),
    ...mapState('customer', ['customer']),
    drawer: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        if (!val) {
          this.$emit('close-drawer', false);
        }
      }
    },
    avatar: function() {
      return this.customer?.avatar?.startsWith('http') ? this.customer.avatar : (`${this.legacyCdnUrl}/upload/${this.customer.avatar}`);
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    install() {
      this.$emit('install', 'side-drawer');
      this.drawer = false;
    },
    onLogout() {
      this.$router.push({ name: 'home' }).catch(() => {});
      this.drawer = false;
    },
    routetoPage(routename) {
      this.drawer = false;
      if (routename) {
        if (routename == 'login') {
          this.$router.push({ name: routename, query: { redirectFullPath: this.$route.path } }).catch(() => {});
        } else {
          this.$router.push({ name: routename }).catch(() => {});
        }
      }
    },
  },
};
</script>

<style scoped>
.bg-green-light {
  background: #c4eec1 !important;
}
.sidedrawerlist .v-list-item__icon {
  margin: 12px 0 !important;
}
.sidedrawerlist .v-list-item__content {
  margin-left: 12px !important;
}
.sidedrawerlist .v-list-item {
  min-height: 35px;
}
.version {
  position: fixed;
  left: 0;
  bottom: 2px;
  z-index: 999;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 1px;
  width: 100%;
  text-align: center;
  padding: 2px 0;
}
@media screen and (min-width: 1024px) {
  .v-navigation-drawer {
    right: calc(50% - 207px);
  }
}
</style>
